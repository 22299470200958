/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });

// sticky footer without going over any content
$('document').ready(function () {
    // running after other logic runs (ajax, etc).
    setTimeout(reposition_footer, 300);

    // also reposition on resize
    $(window).resize(function () {
        reposition_footer();
    });

    // also reposition after ajax calls
    // this is for ajax form requests
    $(window).ajaxComplete(function() {
        // wait for everything to load before reposition
        setTimeout(reposition_footer, 300);
    });
});

function reposition_footer() {
    var $footer = $('footer');

    var doc_height = $(window).outerHeight();
    var footer_height = $footer.outerHeight();
    var footer_top = $footer.position().top + footer_height;

    if (footer_top < doc_height) {
        // margin-top for footer is 45px, don't set to lower value than this
        var new_margin_top = doc_height - footer_top;

        if (new_margin_top > 45) {
            $footer.css('margin-top', new_margin_top + 'px');
        }
    }

    // visibility is hidden be default. Showing after being repositioned.
    // This is to prevent a "jumping effect".
    $footer.css('visibility', 'visible');
}
