window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });


$(document).ready( function () {
    if ($('.table-sortable')) {
        $('.table-sortable').DataTable({
            dom: 'Blfrtip', // display order of table elements https://datatables.net/reference/option/dom
            info: true, // don't display "showing 1 to X of Y entries"
            // paging: false, // turn off pagination
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
            // searching: false, // turn off search box
            order: [], // don't sort on page load 
            // columns: [],
            responsive: true,
            // fixedHeader: true,
            colReorder: { realtime: false }, // drag columns to change order https://datatables.net/extensions/colreorder/
            // select: true,
            buttons: [ // https://datatables.net/extensions/buttons/
                'copy', 'excel', 'csv'
            ]
        });
    }
});
